<template>
  <main class="main_content">
    <!-- Header-->
    <div class="main_visual">
      <div class="main_logo"></div>
      <div class="main_message">当日予約OK！TABLE REQUESTを使って、<br class="sp_visible">美味しいお店を予約しよう！</div>
    </div>
    <div class="container top">
      <!-- tab area-->
      <div class="tab_wrapper">
        <div class="tab_area">
          <a @click="change('1')" :class="{'active': isActive === '1'}" href="#" class="tab reserve">
            <div class="icon reserve"></div>
            予約する
          </a>
          <a @click="change('2')" :class="{'active': isActive === '2'}" href="#" class="tab restaurant_name">
            <div class="icon restaurant"></div>
            店名で探す
          </a>
          <a @click="change('3')" :class="{'active': isActive === '3'}" href="#" class="tab map_search">
            <div class="icon map"></div>
            地図で探す
          </a>
          <a @click="change('4')" :class="{'active': isActive === '4'}" href="#" class="tab michelin_search">
            ミシュラン店を探す
          </a>
        </div>
      </div>

      <!--予約する-->
      <div v-if="isActive === '1'" class="tab_content">
        <Reserve :area-list-full="areaListFull" :area-list="areaList" :category-list="categoryList"/>
      </div>

      <!-- 店名検索 -->
      <div v-else-if="isActive === '2'" class="tab_content">
        <RestaurantSearch/>
      </div>

      <!-- map検索 -->
      <div v-else-if="isActive === '3'" class="tab_content">
        <MapSearch/>
      </div>

      <!-- ミシュラン検索 -->
      <div v-else-if="isActive === '4'" class="tab_content">
        <Michelin :category-list="categoryList"/>
      </div>
    </div>
  </main>
</template>

<script>
import Reserve from './components/Reserve'
import RestaurantSearch from './components/RestaurantSearch'
import MapSearch from './components/MapSearch'
import Michelin from './components/Michelin'

import axios from "axios";
import constant from './constant'

export default {
  name: 'App',
  components: {
    Reserve,
    RestaurantSearch,
    MapSearch,
    Michelin,
  },
  data() {
    return {
      isActive: '1',
      date: new Date(),
      areaListFull: [],
      areaList: [],
      categoryList: [],
    }
  },
  mounted() {
    document.addEventListener('click', function (e) {
      if (e.target.className === 'pac-item') {
        document.getElementById('mapKeyword').value = e.target.innerText;
      } else if (e.target.className === 'pac-item-query') {
        document.getElementById('mapKeyword').value = e.target.parentElement.innerText;
      } else if (e.target.className === 'pac-matched') {
        document.getElementById('mapKeyword').value = e.target.parentElement.parentElement.innerText;
      }
      // if (document.getElementsByClassName('pac-item').length > 0) {
      //   var divsToHide = document.getElementsByClassName("pac-container");
      //   divsToHide[divsToHide.length - 1].style.display = "none";
      // }

      document.addEventListener("focusout", function () {
        setTimeout(function () {
          if (document.getElementsByClassName('pac-item').length > 0) {
            var divsToHide = document.getElementsByClassName("pac-container");
            divsToHide[divsToHide.length - 1].style.display = "none";
          }
        }, 200)
      });
    });

    //get areaList
    axios.get(constant.api_url() + '/v1/area', {
      headers: {
        'x-api-key': constant.x_api_key()
      }
    }).then(res => {
      this.areaListFull = res.data.areas

      //filter areaList
      const temptArrayList = res.data.areas.map(item => Object.values(item)[1]);
      const result = [];
      temptArrayList.map((item) => {
        item.forEach(item => {
          result.push(item)
        })
      })
      this.areaList = result
    }).catch(err => {
      console.log('error', err)
      //do something
    })

    //get categoryList
    axios.get(constant.api_url() + '/v1/tag', {
      headers: {
        'x-api-key': constant.x_api_key(),
      }
    }).then(res => {
      this.categoryList = res.data.tags;
      // this.categoryList = res.data.tags.filter(item => !constant.IGNORE_TAG.includes(item.name));
    }).catch(err => {
      console.log('error', err)
      //do something
    })
  },
  methods: {
    change: function (num) {
      event.preventDefault();
      this.isActive = num
    }
  },
}
</script>

<style scoped>

</style>
