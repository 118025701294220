<template>
  <div>
    <div class="description_text">地名を入力して、近くの店舗を検索できます。</div>
    <div class="tr_form">
      <div class="mb_large">
        <label for="mapKeyword" class="require">地名</label>
        <div class="mapSearch_wrapper">
          <div class="input_wrapper search">
            <input v-model="keyWord" type="text" id="mapKeyword" placeholder="地名を入力" @change="suggest" @focus="clickFocusInput()" @blur="closeDropdown" tabindex="0">
          </div>
          <div>
            <button class="btn primary" @click="search">検索する</button>
          </div>
        </div>
      </div>
      <div id="map" style="width: 100%; height: 400px; border:0"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import constant from '../constant'
export default {
  name: "MapSearch",
  data() {
    return {
      map: null,
      marker: null,
      keyWord: "",
      geocoder: null,
      autocomplete: null,
    }
  },
  mounted() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    window.navigator.geolocation.getCurrentPosition(this.success, this.error, options);
  },

  watch: {
    keyWord: function () {
      if (document.getElementsByClassName('pac-item').length > 0) {
        var divsToHide = document.getElementsByClassName("pac-container");
        divsToHide[0].style.visibility = "visible";
      }
    }
  },

  methods: {
    loadGeoLocation(latlng) {
      const input = document.getElementById('mapKeyword');
      this.autocomplete = new window.google.maps.places.Autocomplete(input);
      //get user current location lat,lng
      const geolocation = 'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyB9792-izcYxRK8OpD_kpefvCQKBcFVm5Q';
      const xhr = new XMLHttpRequest();
      xhr.open('POST', geolocation, false);
      // xhr.onload = function () {
      //   const response = JSON.parse(this.responseText);
      //   console.log(response);
      //   if (this.status === 200) {
      //     latlng = response.location;
      //   }
      // }
      xhr.send();
      this.initMap(latlng);
      this.initShopList(latlng);
    },
    success(pos) {
      const crd = pos.coords;
      this.loadGeoLocation({lat: crd.latitude, lng: crd.longitude});
    },
    error() {
      this.loadGeoLocation({lat: 35.681236, lng: 139.767125});
    },
    deleteMakers() {
      if (this.marker != null) {
        this.marker.setMap(null);
      }
      this.marker = null;
    },
    initMap(latlng) {
      this.setMarker(latlng);
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        center: latlng,
        zoom: 15,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
        clickableIcons: false,
      });
    },
    setMarker(latlng) {
      this.deleteMakers();
      this.map = null;
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: latlng,
        zoom: 15,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
        clickableIcons: false,
      });
      const marker = new window.google.maps.Marker({
        position: latlng,
        map: map,
        draggable: false,
        animation: window.google.maps.Animation.DROP,
      });
      this.map = map
      this.marker = marker
    },
    initGeocoder(keyWord) {
      this.geocoder = new window.google.maps.Geocoder();

      let shopList = null;

      this.geocoder.geocode({
        address: keyWord
      }, (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0].geometry) {
            // get lat,lng object
            const latlng = results[0].geometry.location;
            this.setMarker(latlng);

            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();

            const url = constant.api_url() + '/v1/search/shop?lat=' + lat + '&lng=' + lng + '&radius=2'

            //get shopList
            axios.get(url, {
              headers: {
                'x-api-key': constant.x_api_key()
              }
            }).then(res => {
              shopList = res.data.shops.lists
              let host = location.protocol + '//app.stg.tablerequest.jp';
              if (location.host === 'tablerequest.jp') {
                host = location.protocol + '//app.tablerequest.jp';
              } else if (location.host === 'localhost:8080') {
                host = location.protocol + '//localhost';
              }
              for (let i = 0; i < shopList.length; i++) {
                const detail = host + '/shops/' + shopList[i]['id_code'];
                shopList[i]['detail'] = detail;
              }
              const features = shopList.map(item => {
                return {
                  position: new window.google.maps.LatLng(item.lat, item.lng),
                  detail: item.detail,
                  name: item.name
                }
              })
              const infowindow = new window.google.maps.InfoWindow();
              const icon = {
                url: './img/icon_map_restaurant.svg',
                scaledSize: new window.google.maps.Size(35, 35),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(0, 0)
              };
              // Create markers.
              for (let i = 0; i < features.length; i++) {
                const marker = new window.google.maps.Marker({
                  position: features[i].position,
                  map: this.map,
                  draggable: false,
                  icon: icon
                });
                marker.addListener("click", () => {
                  infowindow.setContent('<div jstcache="33" class="poi-info-window gm-style"><div class="view-link"> <a target="_blank" jstcache="2" href="' + features[i].detail + '"><span>' + features[i].name + '</span></a></div></div>');
                  infowindow.open(this.map, marker);
                });
              }
            }).catch(err => {
              console.log('error', err)
              //do something
            })
          }
        } else if (status == window.google.maps.GeocoderStatus.ZERO_RESULTS) {
          alert("見つかりません");
        } else {
          alert("フリーワード入力を入力してください");
        }
      });
    },
    search() {
      const keyWord = document.getElementById('mapKeyword').value;
      this.initGeocoder(keyWord);
    },
    suggest() {
      window.google.maps.event.clearListeners(document.getElementById('mapKeyword'), 'blur');
      window.google.maps.event.clearListeners(document.getElementById('mapKeyword'), 'keydown');
    },
    clickFocusInput() {
      if (document.getElementsByClassName('pac-item').length > 0) {
        const divsToHide = document.getElementsByClassName("pac-container");
        divsToHide[0].classList.add("pac-container-open");
      }
    },
    closeDropdown() {
      const divsToHide = document.getElementsByClassName("pac-container");
      divsToHide[0].classList.remove("pac-container-open");
    },
    initShopList(latlng) {
      const url = constant.api_url() + '/v1/search/shop?lat=' + latlng.lat + '&lng=' + latlng.lng + '&radius=2'
      this.setMarker(latlng);

      let shopList = null;

      //get shopList
      axios.get(url, {
        headers: {
          'x-api-key': constant.x_api_key()
        }
      }).then(res => {
        shopList = res.data.shops.lists
        let host = location.protocol + '//app.stg.tablerequest.jp';
        if (location.host === 'tablerequest.jp') {
          host = location.protocol + '//app.tablerequest.jp';
        } else if (location.host === 'localhost:8080') {
          host = location.protocol + '//localhost';
        }
        for (let i = 0; i < shopList.length; i++) {
          const detail = host + '/shops/' + shopList[i]['id_code'];
          shopList[i]['detail'] = detail;
        }
        const features = shopList.map(item => {
          return {
            position: new window.google.maps.LatLng(item.lat, item.lng),
            detail: item.detail,
            name: item.name
          }
        })

        const infowindow = new window.google.maps.InfoWindow();
        const icon = {
          url: './img/icon_map_restaurant.svg',
          scaledSize: new window.google.maps.Size(35, 35),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(0, 0)
        };
        // Create markers.
        for (let i = 0; i < features.length; i++) {
          const marker = new window.google.maps.Marker({
            position: features[i].position,
            map: this.map,
            draggable: false,
            icon: icon
          });
          marker.addListener("click", () => {
            infowindow.setContent('<div jstcache="33" class="poi-info-window gm-style"><div class="view-link"> <a target="_blank" jstcache="2" href="' + features[i].detail + '"><span>' + features[i].name + '</span></a></div></div>');
            infowindow.open(this.map, marker);
          });
        }
      }).catch(err => {
        console.log('error', err)
        //do something
      })
    }
  },
}
</script>

<style>
  .pac-container-open {
    display: block !important;
  }
</style>