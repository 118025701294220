const CONVERSION_ENV = "production"; //本番環境にデプロイする場合は、productionに変更してください
const STG_API_URL = "https://api.stg.bespo.biz";
const STG_X_API_KEY = "16nTYM4QiY6qYhnfdShEF9iAQ5ZJ4x9G13uPzL23";
const PRD_API_URL = "https://api.bespo.biz";
const PRD_X_API_KEY = "xnYkLtvLIC9HOgDKsdEeZ5Q6XcuFopy03wErn5PE";

const IGNORE_TAG = [
    '和',
    '洋',
    '中',
    'ミシュラン店（星1）',
    'ミシュラン店（星2）',
    'ミシュラン店（星3）',
    'ビブグルマン'
]
export default {
    IGNORE_TAG,
    api_url: function () {
        if (CONVERSION_ENV == "production") {
            return PRD_API_URL;
        } else {
            return STG_API_URL;
        }
    },
    x_api_key: function () {
        if (CONVERSION_ENV == "production") {
            return PRD_X_API_KEY;
        } else {
            return STG_X_API_KEY;
        }
    },
}