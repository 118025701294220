<template>
  <div>
    <div class="description_text">ミシュラン星獲得店舗を検索できます。</div>
    <form method="get" class="tr_form">
      <div class="form_row mb_large">
        <div class="form_parts">
          <label for="michelinPrefecture" class="require">都道府県</label>
          <div class="select_wrapper arrow_down">
            <select  id="michelinPrefecture" v-model="selectedPrefectures" name="michelinPrefecture" :class="{'error': errors}">
              <option :value="null" disabled>指定されていません</option>
              <option v-for="prefectures in prefecturesList" :value="prefectures" :key="prefectures" >{{ prefectures }}</option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="errors" class="mb_large" style="color: red">都道府県が入力されていません。</div>
      <div class="btn_area">
        <button class="btn primary" type="button" @click="checkForm">検索する</button>
      </div>
    </form>
  </div>

  <Loading v-if="isActive" />

</template>

<script>
import Loading from "./Loading.vue";

export default {
  name: "Michelin",
  props:{
    areaList: {
      type: Array,
      required: true,
      default: () => [],
    },
    categoryList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    Loading
  },
  data() {
    return {
      localAreaList: this.areaList,
      errors: false,
      selectedPrefectures:null,
      prefecturesList: ['北海道','青森','岩手','宮城','秋田','山形','福島','茨城','栃木','群馬','埼玉','千葉','東京',
        '神奈川','新潟','富山','石川','福井','山梨','長野','岐阜','静岡','愛知','三重','滋賀','京都','大阪','兵庫',
        '奈良','和歌山','鳥取','島根','岡山','広島','山口','徳島','香川','愛媛','高知','福岡','佐賀','長崎','熊本','大分',
        '宮崎','鹿児島','沖縄'],
      michelinValues: ['ミシュラン店（星1）', 'ミシュラン店（星2）', 'ミシュラン店（星3）', 'ビブグルマン'],
      isActive: false,
    }
  },
  methods:{
    checkForm: function (e) {
      if (this.selectedPrefectures) {
        const arrayTag = [];
        for (let i = 0; i < this.categoryList.length; i++) {
          if (this.michelinValues.includes(this.categoryList[i].name)) {
            arrayTag.push(this.categoryList[i].id);
          }
        }
        let michelins = arrayTag.join(',');
        let host = location.protocol + "//app.stg.tablerequest.jp";
        if (location.host === 'tablerequest.jp') {
          host = location.protocol + "//app.tablerequest.jp";
        } else if (location.host === 'localhost:8080'){
          host = location.protocol + '//localhost';
        }
        const url = host + '/shops?address=' + this.selectedPrefectures + '&michelins=' + michelins + '&sort=price%2Casc&type_search=address';
        window.location.href = url;
        this.isActive = true;
        return true;
      } 
      

      this.errors = false;

      if (!this.selectedPrefectures) {
        this.errors = true;
      }

      e.preventDefault();
    }
  }
}
</script>

<style>
.velmld-spinner div {
  font-size: 24px;
}
@media screen and (max-width: 480px) {
  #loader-1 {
    width: 100px !important;
    height: 100px !important;
  }
  .velmld-spinner div {
    font-size: 16px;
  }
}
</style>