import { createApp } from 'vue'
import VCalendar from 'v-calendar'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'


// Create the app
createApp(App)
    // Use the plugin with optional defaults
    .use(VCalendar, {})
    .use(VueAxios, axios)
    // Mount the app
    .mount('#app');

window.onunload = function() {
}
window.addEventListener('pageshow', function(event) {  
  if (event.persisted) {    
    window.location.reload();
  }
});
// history.pushState(null, null, null);
// history.replaceState(null, null, null);
// window.addEventListener('popstate', function() {
//   console.log('popstate in');
//   document.getElementsByClassName('velmld-overlay')[0].style.display = 'none';
// });