<template>
  <div class="velmld-full-screen velmld-overlay" id="loading-search">
    <div class="velmld-spinner">
        <div class="loading-image"></div>
        <div>空席情報取得中・・・</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped>

</style>