<template>
  <div>
    <div class="description_text">お店の名前を入力して、お店を検索できます。</div>
    <form @submit="checkForm" method="get" class="tr_form">
      <div class="form_row mb_large">
        <div class="form_parts">
          <label for="r_name2" class="require">店名</label>
          <div class="input_wrapper">
            <input
                id="r_name2"
                v-model="inputRname"
                name="r_name2"
                type="text"
                placeholder="店名を入力"
                :class="{'error': errors}"
            >
          </div>
        </div>
      </div>
      <div v-if="errors" class="mb_large" style="color: red">店名が入力されていません。</div>
      <div class="btn_area">
        <button class="btn primary" type="button" @click="checkForm">検索する</button>
      </div>
    </form>
  </div>

  <Loading v-if="isActive" />
</template>

<script>
import Loading from "./Loading.vue";

export default {
  name: "RestaurantSearch",
  data(){
    return {
      errors: false,
      inputRname: '',
      isActive: false,
    }
  },
  components: {
    Loading
  },
  methods:{
    checkForm: function (e) {
      if (this.inputRname) {
        let host = location.protocol + "//app.stg.tablerequest.jp";
        if (location.host === 'tablerequest.jp') {
          host = location.protocol + "//app.tablerequest.jp";
        } else if (location.host === 'localhost:8080'){
          host = location.protocol + '//localhost';
        }
        const url = host + '/shops?shop_name=' + this.inputRname + '&sort=price%2Casc&type_search=shop_name';
        window.location.href = url;
        this.isActive = true;
        return true;
      }

      this.errors = false;

      if (!this.inputRname) {
        this.errors = true;
      }

      e.preventDefault();
    }
  }
}
</script>

<style>
.velmld-spinner div {
  font-size: 24px;
}
@media screen and (max-width: 480px) {
  #loader-1 {
    width: 100px !important;
    height: 100px !important;
  }
  .velmld-spinner div {
    font-size: 16px;
  }
}
</style>