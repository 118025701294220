<template>
  <div>
    <div class="description_text">エリア、日時、人数など指定の条件を入力して、空いてるお店を検索できます。</div>
    <form method="get"  class="tr_form" >
    <div class="form_row start mb_large">
      <!--エリア-->
      <div class="form_parts">
        <label for="area" class="require">エリア</label>
        <div style="position: relative;">
          <div class="input_wrapper arrow_down">
            <input
              id="area"
              name="area"
              v-on:input="$event.target.value"
              type="text"
              placeholder="エリアを選択"
              @click="showAreaPop=!showAreaPop"
              readonly
              style="cursor:pointer;"
            >
          </div>
          <div v-if="showAreaPop" class="areaPop" >
            <label for="prefectures">都道府県</label>
            <div class="select_wrapper arrow_down">
              <select  id="prefectures" v-model="selectedPrefectures" @change="prefecturesChange">
                <option :value="null" disabled>都道府県を指定してください。</option>
                <option v-for="prefectures in prefecturesList" :value="prefectures" :key="prefectures" >{{ prefectures }}</option>
              </select>
            </div>
            <div v-if="errorPrefectures" style="color: red">{{ errorPrefectures }}</div> 
            <label for="subArea" style="margin-top: 10px">エリア</label>
            <div class="select_wrapper arrow_down">
              <select  id="subArea" v-model="selectedSubArea">
                <option :value="null" disabled>エリアを指定してください。</option>
                <option v-for="area in localAreaList" :value="area.name" :key="area.id">{{ area.name }}</option>
              </select>
            </div>
            <div v-if="errorSubArea" style="color: red">{{ errorSubArea }}</div>            
            <div class="btn_area">
              <button class="btn primary" @click.prevent="setSelectedArea" >決定</button>
            </div>
          </div>
        </div>
      </div>
      <!--日付-->
      <div class="form_parts">
        <label for="date" class="require">日付</label>
        <div class="input_wrapper">
          <input
              id="date"
              v-model="selectedDate"
              name="date"
              type="text"
              placeholder="日付を入力"
              @change="checkDate"
              @click="showCalendar=!showCalendar"
              :class="{'error':selectedDateErrorBorder}"
          >
          <img class="calenderIcon"
               src="img/calendar.svg"
               :class="{ 'background primary': showCalendar }"
               @click="showCalendar=!showCalendar">
          <div v-show="showCalendar">
            <v-date-picker
                v-model="selectedDate"
                @dayclick="calendarClick"
                :attributes='calendarAttrs'
                :min-date='new Date()'
                :select-attribute="calendarSelectAttribute"
                class="calenderArea"/>
          </div>
        </div>
        <span  v-if="selectedDateError" class="formErrorMessage">正しい形式や値を入力してください。</span>
        <span  v-else class="formCheckMessage">{{ DateRemind }}</span>
      </div>
      <!--時間-->
      <div class="form_parts">
        <label for="time" class="require">時間</label>
        <div class="input_wrapper">
          <!-- <input
              id="time"
              v-model="selectedTime"
              name="time"
              type="time"
              placeholder="時間を選択"
              min="00:00"
              max="23:00"
              step="1800"
              :class="{'error':selectedTimeError}"
          > -->
          <flat-pickr id="time" v-model="selectedTime"
                      :class="{'error':selectedTimeError}"
                      :config="config"
          />
        </div>
      </div>
      <!--人数-->
      <div class="form_parts">
        <label for="member" class="require">人数</label>
        <div class="select_wrapper arrow_down" >
          <select id="member" v-model="selectedMember" name="member" :class="{'error': selectedMemberError}">
            <option :value="null" disabled>人数を選択</option>
            <option v-for="member in memberList" :value="member" :key="member">{{ member }}人</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form_row mb_large">
      <!--ジャンル-->
      <div class="form_parts">
        <label for="category">ジャンル</label>
        <div class="select_wrapper arrow_down" >
          <select id="category" v-model="selectedCategory" name="category">
            <option :value="null" disabled>ジャンルを選択</option>
            <option v-for="category in localCategoryList" :value="category.id" :key="category.id">{{ category.name }}</option>
          </select>
        </div>
      </div>
      <!--店名-->
      <div class="form_parts">
        <label for="r_name" >店名</label>
        <div class="input_wrapper">
          <input
              id="r_name"
              v-model="selectedRname"
              name="r_name"
              type="text"
              placeholder="店名を入力"
          >
        </div>
      </div>
      <!--予算-->
      <div class="form_parts">
        <label for="value">予算</label>
        <div class="select_wrapper arrow_down" >
          <select id="value" v-model="selectedValue" name="value">
            <option :value="null" disabled>予算を選択</option>
            <option v-for="value in valueList" :value="value.range" :key="value.id">{{ value.range }}</option>
          </select>
        </div>
      </div>
    </div>
      <div v-if="errors" class="mb_large" style="color: red">{{ errors }}が選択されていません。</div>
    <div class="btn_area">
      <button class="btn primary" type="button" @click="checkForm">検索する</button>
    </div>
  </form>
  </div>

  <Loading v-show="isActive" />
  <!-- <Loading /> -->
</template>

<script>
import moment from "moment";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import constant from '../constant'
import Loading from "./Loading.vue";

export default {
  name: "Reserve",
  props:{
    areaListFull: {
      type: Array,
      required: true,
      default: () => [],
    },
    areaList: {
      type: Array,
      required: true,
      default: () => [],
    },
    categoryList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    flatPickr,
    Loading
  },
  data(){
    return {
      errors: "",
      selectedArea: null,
      selectedAreaError:false,
      selectedDateErrorBorder:false,
      showAreaPop: false,
      localAreaListFull: this.areaListFull,
      localAreaList: this.areaList,
      selectedPrefectures: null,
      prefecturesList:['北海道','青森','岩手','宮城','秋田','山形','福島','茨城','栃木','群馬','埼玉','千葉','東京',
        '神奈川','新潟','富山','石川','福井','山梨','長野','岐阜','静岡','愛知','三重','滋賀','京都','大阪','兵庫',
        '奈良','和歌山','鳥取','島根','岡山','広島','山口','徳島','香川','愛媛','高知','福岡','佐賀','長崎','熊本','大分',
        '宮崎','鹿児島','沖縄'],
      selectedSubArea: null,
      showCalendar: false,
      selectedDate: null,
      selectedDateError: false,
      calendarAttrs: [
        {
          key: 'today',
          highlight: {
            fillMode: 'outline',
            color: 'orange',
          },
          dates: new Date(),
        },
      ],
      calendarSelectAttribute: {
        highlight: {
          fillMode: 'fillMode',
          contentStyle: {
            color:  "#ffffff",
          },
          style: {
            backgroundColor: "#966544",
          },
        },
      },
      DateRemind: "入力形式：YYYY/MM/DD",
      selectedTime: "19:00",
      selectedTimeError: false,
      selectedMember: 2,
      selectedMemberError: false,
      memberList: 48,
      selectedCategory: null,
      localCategoryList: this.categoryList,
      selectedRname: null,
      selectedValue: null,
      valueList: [
        {id: 1, range: '〜4000円'},
        {id: 2, range: '〜6000円'},
        {id: 3, range: '〜8000円'},
        {id: 4, range: '〜10000円'},
        {id: 5, range: '〜12000円'},
        {id: 6, range: '〜15000円'},
        {id: 7, range: '〜20000円'},
        {id: 8, range: '20000円以上'},
      ],
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      },
      errorPrefectures: "",
      errorSubArea: "",
      isActive: false
    }
  },
  mounted() {

    //if over 06:30pm then calendar default date + 1
    const currentTime = moment(new Date()).format("hh")*1
    const currentTimeAmPm = moment(new Date()).format("a")
    const currentMinutes = moment(new Date()).format("mm")*1

    if((currentTime >= 7 || (currentTime == 6 && currentMinutes >= 30)) 
        && currentTimeAmPm === "pm") {
      let dateTime = new Date();
      dateTime = dateTime.setDate(dateTime.getDate()+1);
      dateTime = new Date(dateTime);
      this.selectedDate = moment(dateTime).format("YYYY/MM/DD")
    }else{
      this.selectedDate =  moment(new Date()).format("YYYY/MM/DD")
    }
  },
  watch: {
    categoryList(val) {
      const categoryList = val.filter(item => !constant.IGNORE_TAG.includes(item.name));
      this.localCategoryList = categoryList;
    },
    areaList(val) {
      this.localAreaList = val;
    },
    areaListFull(val) {
      this.localAreaListFull = val;
    },
  },
  methods: {
    prefecturesChange() {
      this.selectedSubArea = null
      const areaListFull = this.localAreaListFull
      const selectedPrefectures = this.selectedPrefectures
      //filter Tokyo
      if(selectedPrefectures === '東京'){
        const result = []
        areaListFull.map((item) => {
          if(item.name === '東京23区' || item.name === '東京23区外'){
            item.lists.forEach(item => {
              result.push(item)
            })
          }
        });
        this.localAreaList = result
      }else{
        //filter Other
        const result = []
        areaListFull.map((item) => {
          if(item.name === selectedPrefectures){
            item.lists.forEach(item => {
              result.push(item)
            })
          }
        });
        // エリアの取得ができなかった場合
        if (result.length === 0) {
          result.push({id: 999999, name: 'すべて'})
        }
        this.localAreaList = result
      }
    },
    setSelectedArea(){
      this.errorPrefectures = this.selectedPrefectures == null ? "都道府県を選択してください。" : "";
      this.errorSubArea = this.selectedSubArea == null ?  "エリアを選択してください。" : "";

      if (this.selectedPrefectures == null || this.selectedSubArea == null) {
        return;
      }

      this.selectedArea = this.selectedSubArea
      document.getElementById("area").value = `${this.selectedPrefectures},${this.selectedSubArea}`;
      this.showAreaPop = false
    },
    clearAllAreaSelection(){
      this.selectedSubArea = null
      this.selectedPrefectures = null
    },
    calendarClick(day) {
      this.selectedDate = day.id
      this.showCalendar = false
      this.selectedDateError = false
      this.selectedDate =moment(new Date(this.selectedDate)).format("YYYY/MM/DD");
    },
    checkDate(){
      this.selectedDateError = false

      const strInputDate = this.selectedDate
      const DA = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      const reg = /^(\d{4})-(\d{2})-(\d{2})$/;

      if (!reg.test(strInputDate)){
        this.selectedDate = ""
        this.selectedDateError = true
        return false;
      }

      const arrD = strInputDate.split("-");
      const y = parseInt(arrD[0], 10);
      const m = parseInt(arrD[1], 10);
      const d = parseInt(arrD[2], 10);

      if (m > 12 || m < 1) {
        this.selectedDate = ""
        this.selectedDateError = true
        return false;
      }

      if (this.isLoopYear(y)) DA[2] = 29;

      if (d > DA[m] || d < 1) {
        this.selectedDate = ""
        this.selectedDateError = true
        return false;
      }
      this.selectedDateError = false
      return true;
    },
    isLoopYear(theYear){
      return (new Date(theYear, 1, 29).getDate() == 29);
    },
    checkForm: function (e) {
      if (this.selectedArea && this.selectedDate && this.selectedTime && this.selectedMember && this.selectedPrefectures) {
        // const date = new Date();
        // const currentTime = date.getHours() + ':' + date.getMinutes();
        let host = location.protocol + '//app.stg.tablerequest.jp';
        if (location.host === 'tablerequest.jp') {
          host = location.protocol + '//app.tablerequest.jp';
        } else if (location.host === 'localhost:8080'){
          host = location.protocol + '//localhost';
        }
        // let dateSelect = new Date(this.selectedDate);
        let paramDate = this.selectedDate;
        let paramTime = this.selectedTime;
        // if (currentTime > '18:30' && moment(dateSelect).format('YYYY/MM/DD') === moment(date).format('YYYY/MM/DD')){
        //   paramDate = dateSelect.setDate(dateSelect.getDate() + 1);
        //   paramDate = moment(dateSelect).format('YYYY/MM/DD');
        // }
        // paramTime = '19:00';
        const objSubAreaSelected = this.localAreaList.filter(data => data.name === this.selectedArea)[0];
        const price = this.selectedValue !== null ? this.selectedValue.replace('〜', '~') : '';
        const tagId = this.selectedCategory !== null ? this.selectedCategory : '';
        const shopName = this.selectedRname !== null ? this.selectedRname : '';
        let url = host + '/shops?address=' + this.selectedPrefectures
            + '&date=' + paramDate + '&time=' + paramTime
            + '&number_of_people=' + this.selectedMember
            + '&tag_ids=' + tagId + '&shop_name=' + shopName + '&price=' + price
            + '&sort=price,asc&type_search=seat';
        // 検索対象が「すべて」でない場合は、緯度経度を付与してAPIへアクセス
        if (objSubAreaSelected.id !== 999999) {
          url = url + '&area=' + objSubAreaSelected.lat + ',' + objSubAreaSelected.lng
        } else {
          url = url + '&area=' + 0 + ',' + 0
        }

        this.isActive = true;
        window.location.href = url;
        return;
      }

      this.selectedAreaError = false
      this.selectedDateErrorBorder = false
      this.selectedTimeError = false
      this.selectedMemberError = false

      const errorsArray = [];
      if (!this.selectedPrefectures && !this.selectedArea) {
        this.selectedAreaError = true
        errorsArray.push('都道府県');
        errorsArray.push('エリア')
      } else if (!this.selectedPrefectures && this.selectedArea) {
        this.selectedAreaError = true
        errorsArray.push('都道府県')
      } else if (this.selectedPrefectures && !this.selectedArea) {
        this.selectedAreaError = true
        errorsArray.push('エリア')
      }

      if (!this.selectedDate) {
        this.selectedDateErrorBorder = true
        errorsArray.push('日付')
      }
      if (!this.selectedTime) {
        this.selectedTimeError = true
        errorsArray.push('時間')
      }
      if (!this.selectedMember) {
        this.selectedMemberError = true
        errorsArray.push('人数')
      }

      let errors = ""
      for(let i = 0 ; i < errorsArray.length ; i++){
          errors = errors + errorsArray[i] + '、'
      }
      errors = errors.substr(0, errors.length - 1)
      this.errors = errors
      e.preventDefault();
    },
  },
}
</script>

<style>
.velmld-spinner div {
  font-size: 24px;
}
@media screen and (max-width: 480px) {
  #loader-1 {
    width: 100px !important;
    height: 100px !important;
  }
  .velmld-spinner div {
    font-size: 16px;
  }
}
</style>